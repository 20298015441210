:root {
  --scroll-bar-color: #c5c5c5;
  --scroll-bar-bg-color: #f6f6f6;
  
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("./assest/img/bg-design.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: rgb(15, 25, 50);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--scroll-bar-bg-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-color);
  border-radius: 20px;
  border: 3px solid var(--scroll-bar-bg-color);
}

img {
  max-width: 100%;

}
.video-player {
  min-width: 320px;
  height: 480px;
  margin-top: 10px;
}
.iframe-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 480px;
}

#zsfeedbackFrame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
}