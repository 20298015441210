.search {
  height: 30px;
  width: 100%;
  border: 0;
  outline: 0;
  border-radius: 4px;
  padding: 0 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: white;
}

.header-search {
  height: 30px;
  width: 100%;
  border: 1px solid #ffcccc;
  border-radius: 4px;
  padding: 0 5px;
}
